.name {
  position: relative;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  font-family: "Rammetto One";
  font-size: 2rem;
  display: block;
  // max-width: 20rem;
  margin-bottom: 0.75rem;
  font-weight: normal;
}

.name-split {
  position: relative;
  width: 100%;
  height: 40px;
  line-height: 40px;
  will-change: transform, opacity;
  overflow: hidden;

  > div {
    overflow: hidden;
  }
}

.title {
  margin-bottom: 2rem;
  will-change: opacity;
}

.intro {
  max-width: 40ch;
  padding-right: 2rem;
  display: block;
  font-size: 1rem;
  margin: 0;
}

.logo {
  margin-bottom: 1.25rem;
  max-width: 10rem;
  max-height: 11rem;
}

@media screen and (min-width: 768px) {
  // .title {
  //   display: flex;
  // }

  .intro {
    // max-width: 24rem;
    padding-top: 3px;
  }

  .name {
    // margin-bottom: 0;
  }
}
