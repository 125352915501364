$borderRadius: 12px;
$timing: 300ms;
$timingLong: 500ms;
$easing: cubic-bezier(0, 0, 0.2, 1);

.card {
  outline: 0;
  border: 0;
  display: inline-flex;
  flex-direction: column;
  padding: 0;
  font-size: 0.9rem;
  margin: 0 1rem;
  height: var(--itemHeight);
  width: var(--itemWidth);
  white-space: normal;
  justify-content: space-between;
  position: relative;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &.is-active {
    z-index: 1;

    .shadow::before {
      opacity: 0.5;
      transform: none;
    }

    .shadow::after {
      opacity: 1;
      transform: none;
    }
  }
}

.backdrop {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  will-change: transform;
  overflow: hidden;
  transform-origin: bottom;
  width: var(--itemWidth);
  height: var(--itemHeight);
}

.inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.card_content {
  position: absolute;
  z-index: 1;
  padding: 2.5rem 2.25rem;
}

.card_title {
  font-size: 1rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
}

.card_media {
  width: 100%;
  pointer-events: none;
  will-change: transform;
  transform-origin: bottom;
  position: relative;
  // top: 2px;
}

.Icon-project.Icon--card,
.Icon-fallback.Icon--card {
  // height: 2rem;
  margin-bottom: 1rem;
  // width: 6rem;
  pointer-events: none;
}

@media screen and (min-width: 768px) {
  .card {
    font-size: 1rem;
  }

  .card_content {
    padding: 3rem 2.5rem;
  }

  .Icon-project.Icon--card,
  .Icon-fallback.Icon--card {
    margin-bottom: 2rem;
  }
}
