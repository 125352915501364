.shadow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: var(--itemWidth);
  height: var(--itemHeight);
  will-change: opacity, transform;
}

.shadow-upper {
  opacity: 0.1;
  transform: scale(0.75);
  transform-origin: top right;
  box-shadow: -60px 60px 40px 10px rgba(#333, 0.15);
}

// before
.shadow-lower {
  box-shadow: -4px 3px 7px 1px rgba(#666, 0.06);
}
