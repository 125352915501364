:root {
  --padding: 6rem 10%;
  --padding-lg: 3rem;

  --itemWidth: 13.25rem;
  --itemHeight: 20rem;

  @media screen and (min-width: 768px) {
    --itemWidth: 17.8rem;
    --itemHeight: 26rem;
  }
}

@import url("https://fonts.googleapis.com/css?family=Rammetto+One&display=swap");

::selection {
  background: #333;
  color: #fff;
}
::-moz-selection {
  background: #333;
  color: #fff;
}

button:focus {
  outline: none;
}

html {
  font-size: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  background-color: #333;
}

body {
  margin: 0;
  // font-size: 0.95rem;
  font-family: sans-serif;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-ligatures: common-ligatures;
  line-height: 1.35;
  letter-spacing: -0.4px;
  color: #333;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #333;
    color: #fff;
  }

  .Social-anchor-circle {
    background-color: #fff;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.u-visually-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

a {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

a:hover,
a:focus {
  color: inherit;
}

a:active,
a:hover,
a:focus {
  outline: 0;
}

button {
  text-transform: none;
  cursor: pointer;
  background-color: transparent;
  margin: 0;
  border: 0;
  padding: 0;
  text-align: left;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

a,
button,
input,
select,
textarea,
label,
summary {
  touch-action: manipulation;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2 {
  font-size: 1.3rem;
  margin: 0;
}

h3 {
  font-size: inherit;
  margin: 0 0 1rem;
}

p {
  margin: 0 0 1rem;
}

h2 {
  margin: 0 0 0.5rem;
}

.root {
  overflow: hidden;
  min-height: 100vh;
}

.container {
  margin: 0 auto;
  padding: 1.5rem 1.25rem; //var(--padding);
  max-width: 60rem;
}

.section {
  margin-bottom: 5rem;
}

.sub-heading-wrapper {
  overflow: hidden;
  margin-bottom: 1.35rem;
}

.sub-heading {
  font-family: "Rammetto One";
  font-size: 1.25rem;
  font-weight: normal;
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .container {
    position: inherit;
    padding: var(--padding-lg);
  }

  body {
    font-size: 0.85rem;
  }
}
